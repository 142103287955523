import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from 'environments/environment';
import Parse from 'parse';

// Enable production mode
if (environment.production) {
    enableProdMode();
}

// Initialize Parse with proper configuration
Parse.initialize(environment.appId, 'javascript-key');
Parse.serverURL = environment.serverUrl;
Parse.idempotency = true;

// Declare ngRef on window for accessing Angular services from outside Angular
declare global {
    interface Window {
        ngRef: any;
    }
}

// Bootstrap the application
bootstrapApplication(AppComponent, appConfig)
  .then(ref => {
    // Store a reference to the application injector
    window['ngRef'] = { injector: ref.injector };
  })
  .catch((err) => console.error('Error bootstrapping application:', err));
