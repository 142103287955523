import { Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { CanActivate, CanActivateChild, CanMatch, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import Parse from 'parse';
import { ParseUser } from 'app/core/user/parse-user.model';
import { inject } from '@angular/core';
import { FuseLoadingService } from '@fuse/services/loading';

@Injectable({providedIn: 'root'})
export class NoAuthGuard implements CanActivate, CanActivateChild, CanMatch
{
    /**
     * Constructor
     */
    private router = inject(Router);
    private fuseLoadingService = inject(FuseLoadingService);
    
    // Track which routes we've already checked to prevent redundant checks
    private checkedRoutes = new Set<string>();

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can activate
     *
     * @param route
     * @param state
     */
    canActivate(): Observable<boolean | UrlTree>
    {
        return this.check();
    }

    /**
     * Can activate child
     *
     * @param childRoute
     * @param state
     */
    canActivateChild(): Observable<boolean | UrlTree>
    {
        return this.check();
    }

    /**
     * Can match
     *
     * @param route
     * @param segments
     */
    canMatch(): Observable<boolean | UrlTree>
    {
        return this.check();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Check the authenticated status
     *
     * @param segments
     * @private
     */
    private check(): Observable<boolean | UrlTree>
    {
        // Get the current URL
        const currentUrl = this.router.url;
        
        // If we've already checked this route, allow access without rechecking
        if (this.checkedRoutes.has(currentUrl)) {
            return of(true);
        }
        
        // Add the current URL to the checked routes
        this.checkedRoutes.add(currentUrl);
        
        // Check if the app is initialized
        if (!Parse.applicationId) {
            // Show loading indicator while initializing
            this.fuseLoadingService.show();
            
            // Wait for initialization by retrying
            return new Observable<boolean | UrlTree>(observer => {
                const checkInterval = setInterval(() => {
                    if (Parse.applicationId) {
                        clearInterval(checkInterval);
                        this.fuseLoadingService.hide();
                        
                        // Now that we're initialized, check auth status
                        this.checkAuthStatus().subscribe(result => {
                            observer.next(result);
                            observer.complete();
                        });
                    }
                }, 100);
            });
        }
        
        // If already initialized, proceed with auth check
        return this.checkAuthStatus();
    }
    
    /**
     * Check authentication status
     */
    private checkAuthStatus(): Observable<boolean | UrlTree> {
        return of(ParseUser.current()).pipe(
            switchMap((user) => {
                // If the user is logged in, redirect to the home page
                if (user) {
                    return of(this.router.parseUrl(''));
                }
                
                // Allow access to the route
                return of(true);
            })
        );
    }
}
