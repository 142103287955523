// Admin DEV
export const environment = {
  production: false,
  serverUrl: 'https://api-dev.syzl.io/api',
  graphQlUrl: 'https://api-dev.syzl.io/graphql',
  appId: '6JMrpjIj8L',
  languages: [{
    id: 'en',
    locale: 'en-US',
    default: true,
    label: 'English',
  }],
  showLanguageSelector: true,
  googleMapsApiKey: 'AIzaSyDymx2MfM_eodBnyZKHoowzUvsTF68zQuE',
  googleClientId: '222858438652-qvlmhlb3qbhmtjuvfp0k6dj6rsp8qe8a.apps.googleusercontent.com',
  pageSize: 25,
  firebase: {
    apiKey: 'AIzaSyDymx2MfM_eodBnyZKHoowzUvsTF68zQuE',
    authDomain: 'syzl-dev.firebaseapp.com',
    projectId: 'syzl-dev',
    storageBucket: 'syzl-dev.appspot.com',
    messagingSenderId: '222858438652',
    appId: '1:222858438652:web:b135dd8d88b4621a483479',
    measurementId: 'G-CZTSXXH573',
  },
  hsEnvId: '22159103',
  hsPlaceSchemaId: '2-7194607',
  hsBookingSchemaId: '2-7950673',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
