<ng-container *transloco="let t">
  <!-- Button -->
  <button mat-icon-button [matMenuTriggerFor]="userActions">
    <span class="relative">
      <img class="w-7 h-7 rounded-full" *ngIf="showAvatar && user.photo" [src]="user.photo._url">
      <mat-icon *ngIf="!showAvatar || !user.photo" [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
      <span class="absolute right-0 bottom-0 w-2 h-2 rounded-full"></span>
    </span>
  </button>

  <mat-menu [xPosition]="'before'" #userActions="matMenu">
    <button mat-menu-item>
      <span class="flex flex-col leading-none">
        <span>{{ t('SIGNED_IN_AS') }}</span>
        <span class="mt-1.5 text-md font-medium">{{user.email}}</span>
      </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="onSettingsButtonClicked()">
      <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
      <span>{{ t('SETTINGS') }}</span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="onSignOutButtonClicked(); $event.stopPropagation();">
      <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
      <span>{{ t('SIGN_OUT') }}</span>&nbsp;
      <mat-progress-spinner *ngIf="isLoading" [diameter]="16" [color]="'primary'" [mode]="'indeterminate'">
      </mat-progress-spinner>
    </button>
  </mat-menu>

</ng-container>