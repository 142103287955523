import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import Parse from 'parse';
import { ParseUserService } from '../user/parse-user.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    
    constructor(
        private _parseUserService: ParseUserService,
        private _ngZone: NgZone
    ) {}
    
    handleError(error: any): void {
        // Check if this is a Parse error
        if (error && error.code !== undefined) {
            // Check specifically for invalid session token
            if (error.code === Parse.Error.INVALID_SESSION_TOKEN) {
                console.warn('Invalid session token detected in global error handler, triggering session expiration');
                
                // Use NgZone to ensure Angular change detection is triggered
                this._ngZone.run(() => {
                    this._parseUserService.onUserSessionExpired();
                });
                
                return;
            }
        }
        
        // Log the error to console
        console.error('Global error handler caught an error:', error);
    }
}
