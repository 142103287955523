import { Injectable } from '@angular/core';
import { ParseUser } from './parse-user.model';
import * as Parse from 'parse';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ParseUserService {

  userCreated$: Observable<ParseUser>;
  userDestroyed$: Observable<ParseUser>;
  userSessionExpired$: Observable<boolean>;

  private _userCreatedSource = new Subject<ParseUser>();
  private _userDestroyedSource = new Subject<ParseUser>();
  private _userSessionExpiredSource = new Subject<boolean>();

  constructor() {
    this.userCreated$ = this._userCreatedSource.asObservable();
    this.userDestroyed$ = this._userDestroyedSource.asObservable();
    this.userSessionExpired$ = this._userSessionExpiredSource.asObservable();
  }



  onUserCreated(user: ParseUser): void {
    this._userCreatedSource.next(user);
  }

  onUserDestroyed(user: ParseUser): void {
    this._userDestroyedSource.next(user);
  }

  onUserSessionExpired(): void {
    this._userSessionExpiredSource.next(true);
  }

  find(params: any = {}): Promise<{ total: number; users: ParseUser[] }> {
    return Parse.Cloud.run('getUsers', params);
  }

  canLogin(username: string): Promise<boolean> {
    return Parse.Cloud.run('canLogin', { username });
  }

  login(params: any = {}): Promise<boolean> {
    return Parse.Cloud.run('loginInCloud', params);
  }

  async loginGoogle(credential: string): Promise<string> {
    return await Parse.Cloud.run('loginGoogleAdmin', { credential });
  }

  findOne(id: string): Promise<ParseUser> {
    return Parse.Cloud.run('getUser', { id });
  }

  createOrUpdate(data: any = {}): Promise<ParseUser> {
    if (data.objectId) {
      return Parse.Cloud.run('updateUser', data);
    } else {
      return Parse.Cloud.run('createUser', data);
    }
  }

  delete(id: string): Promise<ParseUser> {
    return Parse.Cloud.run('destroyUser', { id });
  }

  adminUserFetch(id: string): Promise<ParseUser> {
    return Parse.Cloud.run('adminUserFetch', { id });
  }
}
