/* eslint-disable @typescript-eslint/naming-convention */
import { Injector } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { environment } from 'environments/environment';
import { TranslocoService } from '@ngneat/transloco';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TransAndSnackComponent } from './trans-and-snack.component';

enum ViewState {
  LOADING,
  ERROR,
  EMPTY,
  CONTENT,
}

enum ViewMode {
  CREATE,
  UPDATE,
}

export abstract class BaseComponent extends TransAndSnackComponent {

  public state: ViewState;
  public ViewState = ViewState;

  public mode: ViewMode;
  public ViewMode = ViewMode;

  protected router: Router;
  protected activatedRoute: ActivatedRoute;

  private title: Title;

  constructor(injector: Injector) {
    super();
    this.router = injector.get(Router);
    this.activatedRoute = injector.get(ActivatedRoute);
    this.title = injector.get(Title);
  }

  public get isProduction(): boolean {
    return environment.production;
  }

  public get pageSize(): number {
    return environment.pageSize;
  }

  public setPageTitle(title: string): void {
    const str = this.getTrans('APP_NAME');
    this.title.setTitle(`${title} - ${str}`);
  }

  showLoadingView(): void {
    this.state = ViewState.LOADING;
  }

  showEmptyView(): void {
    this.state = ViewState.EMPTY;
  }

  showErrorView(): void {
    this.state = ViewState.ERROR;
  }

  showContentView(): void {
    this.state = ViewState.CONTENT;
  }

  /**
   * Navigates to a new page
   *
   * @param page
   * @param [queryParams]
   * @returns to
   */
  navigateTo(page: any, queryParams: any = {}): Promise<boolean> {
    return this.router.navigate([page], { queryParams: queryParams });
  }


  /**
   * Navigates to relative
   *
   * @param page
   * @param [queryParams]
   * @returns to relative
   */
  navigateToRelative(page: any, queryParams: any = null): Promise<boolean> {
    return this.router.navigate([page], {
      queryParams: queryParams,
      relativeTo: this.activatedRoute,
      queryParamsHandling: queryParams ? 'merge' : '',
    });
  }

  getParams(): Params {
    return this.activatedRoute.snapshot.params;
  }

  getQueryParams(): Params {
    return this.activatedRoute.snapshot.queryParams;
  }
}
