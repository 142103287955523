import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {
    EnvironmentProviders,
    Provider,
    inject,
} from '@angular/core';
import { authInterceptor } from 'app/core/auth/auth.interceptor';
import Parse from 'parse';

export const provideAuth = (): Array<Provider | EnvironmentProviders> => {
    return [
        provideHttpClient(withInterceptors([authInterceptor])),
        // Remove the provideEnvironmentInitializer - it's causing circular dependencies during initialization
    ];
};
