import { provideHttpClient, withInterceptors, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
    ApplicationConfig,
    inject,
    isDevMode,
    provideAppInitializer,
    APP_INITIALIZER,
    ErrorHandler
} from '@angular/core';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { provideFuse } from '@fuse';
import { fuseLoadingInterceptor } from '@fuse/services/loading';
import { FuseLoadingService } from '@fuse/services/loading/loading.service';
import { TranslocoService, provideTransloco } from '@jsverse/transloco';
import { provideTranslocoLocale } from '@jsverse/transloco-locale';
import { appRoutes } from 'app/app.routes';
import { provideAuth } from 'app/core/auth/auth.provider';
import { provideAuthService } from 'app/core/auth/auth.service';
import { AppInitializerService, initializeApp } from 'app/core/init/app-initializer.service';
import { provideIcons } from 'app/core/icons/icons.provider';
import { MockApiService } from 'app/mock-api';
import { firstValueFrom } from 'rxjs';
import { TranslocoHttpLoader } from './core/transloco/transloco.http-loader';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { environment } from '../environments/environment';
import Parse from 'parse';
import { ParseErrorInterceptor } from './core/interceptor/parse-error.interceptor';
import { GlobalErrorHandler } from './core/error/global-error-handler';

// Create a HttpInterceptor that excludes the fuseLoadingInterceptor during initialization
const initialHttpConfig = withInterceptors([
    // Exclude the fuseLoadingInterceptor - we'll add it later after initialization
]);

// Regular HTTP config with all interceptors
const regularHttpConfig = withInterceptors([
    fuseLoadingInterceptor,
    // Add any other interceptors here
]);

export const appConfig: ApplicationConfig = {
    providers: [
        provideAnimations(),
        
        // Use the initial HTTP config without the loading interceptor
        // This prevents the loading bar from showing during initialization
        provideHttpClient(initialHttpConfig),

        // Register the global error handler
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        
        // Register the Parse error interceptor
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ParseErrorInterceptor,
            multi: true
        },
        
        provideRouter(
            appRoutes,
            withInMemoryScrolling({ scrollPositionRestoration: 'enabled' })
        ),

        // Material Date Adapter
        {
            provide: DateAdapter,
            useClass: LuxonDateAdapter,
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'D',
                },
                display: {
                    dateInput: 'DDD',
                    monthYearLabel: 'LLL yyyy',
                    dateA11yLabel: 'DD',
                    monthYearA11yLabel: 'LLLL yyyy',
                },
            },
        },
        
        // Main application initializer - this handles all initialization steps in the correct order
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [AppInitializerService],
            multi: true,
        },

        // Transloco Config
        provideTransloco({
            config: {
                availableLangs: [
                    {
                        id: 'en',
                        label: 'English',
                    },
                    {
                        id: 'es',
                        label: 'Spanish',
                    },
                ],
                defaultLang: 'en',
                fallbackLang: 'en',
                reRenderOnLangChange: true,
                prodMode: !isDevMode(),
            },
            loader: TranslocoHttpLoader,
        }),
        
        // Transloco initializer
        provideAppInitializer(() => {
            const translocoService = inject(TranslocoService);
            const defaultLang = translocoService.getDefaultLang();
            translocoService.setActiveLang(defaultLang);

            return firstValueFrom(translocoService.load(defaultLang));
        }),

        // TranslocoLocale provider
        provideTranslocoLocale({
            langToLocaleMapping: {
                en: 'en-US',
                es: 'es-ES'
            }
        }),

        // Apollo configuration
        {
            provide: APOLLO_OPTIONS,
            useFactory: (httpLink: HttpLink) => {
                // Add auth headers to every request
                const auth = setContext((_, { headers }) => {
                    // Get the authentication token from Parse
                    const token = Parse.User.current()?.getSessionToken();
                    
                    // Return the headers to the context so httpLink can read them
                    return {
                        headers: {
                            ...headers,
                            'X-Parse-Session-Token': token || '',
                            'X-Parse-Application-Id': environment.appId,
                        },
                    };
                });

                // Use the auth link with the http link
                const link = auth.concat(
                    httpLink.create({
                        uri: environment.graphQlUrl,
                    })
                );

                return {
                    cache: new InMemoryCache(),
                    link
                };
            },
            deps: [HttpLink],
        },

        // Fuse initialization 
        provideFuse({
            mockApi: {
                delay: 0,
                service: MockApiService,
            },
            fuse: {
                layout: 'classy',
                scheme: 'light',
                screens: {
                    sm: '600px',
                    md: '960px',
                    lg: '1280px',
                    xl: '1440px',
                },
                theme: 'theme-default',
                themes: [
                    {
                        id: 'theme-default',
                        name: 'Default',
                    },
                    {
                        id: 'theme-brand',
                        name: 'Brand',
                    },
                    {
                        id: 'theme-teal',
                        name: 'Teal',
                    },
                    {
                        id: 'theme-rose',
                        name: 'Rose',
                    },
                    {
                        id: 'theme-purple',
                        name: 'Purple',
                    },
                    {
                        id: 'theme-amber',
                        name: 'Amber',
                    },
                ],
            },
        }),

        // Icons
        provideIcons(),

        // Auth
        provideAuth(),
    ],
};
