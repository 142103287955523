<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation class="dark bg-gray-900 print:hidden" [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'" [navigation]="navigation.default" [opened]="!isScreenSmall">
  <!-- Navigation header hook -->
  <ng-container fuseVerticalNavigationContentHeader>
    <!-- Logo -->
    <div class="flex items-center h-20 p-6 pb-0">
      <img class="hidden dark:flex w-8" src="assets/images/logo/logo-light.png" alt="Logo image">
    </div>
  </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

  <!-- Header -->
  <div
    class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
    <!-- Navigation toggle button -->
    <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
      <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
    </button>
    <!-- Components -->
    <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
      <languages></languages>
      <user></user>
    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-auto">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>

</div>