import { Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';

@Injectable({
  providedIn: 'root'
})
export class FusePlatformService
{
  osName = 'os-unknown';

  /**
     * Constructor
     */
  constructor(private _platform: Platform)
  {
    // If the platform is not a browser, return immediately
    if ( !this._platform.isBrowser )
    {
      return;
    }

    // Windows
    if ( navigator.userAgent.includes('Win') )
    {
      this.osName = 'os-windows';
    }

    // Mac OS
    if ( navigator.userAgent.includes('Mac') )
    {
      this.osName = 'os-mac';
    }

    // Unix
    if ( navigator.userAgent.includes('X11') )
    {
      this.osName = 'os-unix';
    }

    // Linux
    if ( navigator.userAgent.includes('Linux') )
    {
      this.osName = 'os-linux';
    }

    // iOS
    if ( this._platform.IOS )
    {
      this.osName = 'os-ios';
    }

    // Android
    if ( this._platform.ANDROID )
    {
      this.osName = 'os-android';
    }
  }

}
