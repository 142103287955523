/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';

const defaultNavItems: FuseNavigationItem[] = [
    {
      id: 'people',
      title: 'People',
      type: 'aside',
      icon: 'heroicons_outline:user-group',
      children: [
        {
          type: 'group',
          title: 'Accounts'
        },
        {
          id: 'users',
          title: 'Users',
          type: 'basic',
          icon: 'heroicons_outline:user-circle',
          link: '/users',
        },
        {
          id: 'vendors',
          title: 'Vendors',
          type: 'basic',
          icon: 'heroicons_outline:currency-dollar',
          link: '/vendors',
        },
        {
          type: 'divider'
        },
        {
          type: 'group',
          title: 'Activity'
        },
        {
          id: 'conversations',
          title: 'Conversations',
          type: 'basic',
          icon: 'heroicons_outline:chat-bubble-left-right',
          link: '/conversations',
        },
        {
          id: 'calls',
          title: 'Calls',
          type: 'basic',
          icon: 'heroicons_outline:phone',
          link: '/calls',
        },
        {
          id: 'flagged',
          title: 'Flagged',
          type: 'basic',
          icon: 'heroicons_outline:flag',
          link: '/flagged'
        },
        {
          id: 'reviews',
          title: 'Reviews',
          type: 'basic',
          icon: 'heroicons_outline:star',
          link: '/reviews',
        },
      ]
    },
    {
      id: 'credentials',
      title: 'Credentials',
      type: 'aside',
      icon: 'heroicons_outline:folder',
      children: [
        {
          type: 'group',
          title: 'Documents'
        },
        {
          id: 'insurance',
          title: 'Insurance',
          type: 'basic',
          icon: 'heroicons_outline:document-text',
          link: '/user-insurance'
        },
        {
          id: 'food_safety',
          title: 'Food Safety',
          type: 'basic',
          icon: 'heroicons_outline:shield-check',
          link: '/user-food-safety'
        },
        {
          type: 'divider'
        },
        {
          type: 'group',
          title: 'Applications'
        },
        {
          id: 'goWalnut',
          title: 'GoWalnut Applications',
          type: 'basic',
          icon: 'heroicons_outline:document-text',
          link: '/goWalnut'
        },
        {
          id: 'fuse',
          title: 'Fuse Applications',
          type: 'basic',
          icon: 'heroicons_outline:document-text',
          link: '/fuse'
        },
      ]
    },
    {
      id: 'listings',
      title: 'Listings',
      type: 'aside',
      icon: 'heroicons_outline:map',
      children: [
        {
          type: 'group',
          title: 'Places'
        },
        {
          id: 'section',
          title: 'Sections',
          type: 'basic',
          icon: 'heroicons_outline:map',
          link: '/places',
        },
        {
          id: 'kitchen',
          title: 'Kitchens',
          type: 'basic',
          icon: 'heroicons_outline:building-storefront',
          link: '/facilities',
        },
        {
          id: 'categories',
          title: 'Categories',
          type: 'basic',
          icon: 'heroicons_outline:tag',
          link: '/categories',
        },
        {
          id: 'equipment_categories',
          title: 'Equipment Categories',
          type: 'basic',
          icon: 'heroicons_outline:sparkles',
          link: '/equipment-categories',
        },
        {
          id: 'features',
          title: 'Features',
          type: 'basic',
          icon: 'heroicons_outline:truck',
          link: '/features',
        },
        {
          id: 'dietary_restrictions',
          title: 'Dietary Restrictions',
          type: 'basic',
          icon: 'heroicons_outline:beaker',
          link: '/dietary-restriction',
        },
        {
          id: 'place_cities',
          title: 'City Availability',
          type: 'basic',
          icon: 'heroicons_outline:map-pin',
          link: '/place-city',
        },
        {
          type: 'divider'
        },
        {
          type: 'group',
          title: 'Add-ons'
        },
        {
          id: 'booking_add_ons',
          title: 'Booking add-ons',
          type: 'basic',
          icon: 'heroicons_outline:plus-circle',
          link: '/booking-add-ons'
        },
        {
          id: 'booking_add_on_categories',
          title: 'Booking add-on categories',
          type: 'basic',
          icon: 'heroicons_outline:presentation-chart-line',
          link: '/booking-add-on-categories'
        },
        {
          id: 'booking_add_on_templates',
          title: 'Booking add-on templates',
          type: 'basic',
          icon: 'heroicons_outline:document-duplicate',
          link: '/booking-add-on-templates'
        }
      ]
    },
    {
      id: 'sales',
      title: 'Sales',
      type: 'aside',
      icon: 'heroicons_outline:currency-dollar',
      children: [
        {
          type: 'group',
          title: 'Purchases'
        },
        {
          id: 'bookings',
          title: 'Bookings',
          type: 'basic',
          icon: 'heroicons_outline:book-open',
          link: '/bookings',
        },
        {
          id: 'syzl_subscriptions',
          title: 'Syzl Subscriptions',
          type: 'basic',
          icon: 'heroicons_outline:document-text',
          link: '/syzl-subscriptions'
        },
        {
          id: 'smarter_u_account',
          title: 'SmarterU Accounts',
          type: 'basic',
          icon: 'heroicons_outline:academic-cap',
          link: '/smarter-u-account'
        },
        {
          id: 'user_packages',
          title: 'Host Packages (WIP)',
          type: 'basic',
          icon: 'heroicons_outline:megaphone',
          link: '/user-packages',
        },
        {
          type: 'divider'
        },
        {
          type: 'group',
          title: 'Discounts'
        },
        {
          id: 'booking_fees',
          title: 'Booking Fee Promos',
          type: 'basic',
          icon: 'heroicons_outline:receipt-percent',
          link: '/booking-fees',
        },
        {
          id: 'customer_credits',
          title: 'Customer Credits',
          type: 'basic',
          icon: 'heroicons_outline:receipt-refund',
          link: '/customer-credits',
        },
        {
          id: 'promo_codes',
          title: 'Promo Codes',
          type: 'basic',
          icon: 'heroicons_outline:percent-badge',
          link: '/promo-codes',
        },
        {
          type: 'divider'
        },
        {
          type: 'group',
          title: 'Products'
        },
        {
          id: 'packages',
          title: 'Packages (WIP)',
          type: 'basic',
          icon: 'heroicons_outline:megaphone',
          link: '/packages',
        },
        {
          id: 'smarter_u_dummy',
          title: 'SmarterU Dummies',
          type: 'basic',
          icon: 'heroicons_outline:puzzle-piece',
          link: '/smarter-u-dummy'
        },
        {
          id: 'twilio_numbers',
          title: 'Twilio Numbers',
          type: 'basic',
          icon: 'heroicons_outline:phone',
          link: '/twilio-numbers'
        },
        {
          type: 'divider'
        },
        {
          type: 'group',
          title: 'Bulk'
        },
        {
          id: 'bulk_companies',
          title: 'Bulk Companies',
          type: 'basic',
          icon: 'heroicons_outline:briefcase',
          link: '/bulk-companies',
        },
        {
          id: 'bulk_deals',
          title: 'Bulk Deals',
          type: 'basic',
          icon: 'heroicons_outline:briefcase',
          link: '/bulk-deals',
        },
        {
          id: 'bulk_bookings',
          title: 'Bulk Bookings',
          type: 'basic',
          icon: 'heroicons_outline:briefcase',
          link: '/bulk-bookings',
        },
        {
          type: 'divider'
        },
        {
          type: 'group',
          title: 'Payments'
        },
        {
          id: 'booking_failed_payments',
          title: 'Booking Failed Payments',
          type: 'basic',
          icon: 'heroicons_outline:exclamation-triangle',
          link: '/booking-failed-payments'
        },
        {
          id: 'stripe_accounts',
          title: 'Stripe Accounts',
          type: 'basic',
          icon: 'heroicons_outline:credit-card',
          link: '/admin/stripe-accounts'
        }
      ]
    },
    {
      id: 'marketing',
      title: 'Marketing',
      type: 'aside',
      icon: 'heroicons_outline:document-text',
      children: [
        {
          type: 'group',
          title: 'ICP Tooling'
        },
        {
          id: 'icp',
          title: 'Ideal Customer Profiles',
          type: 'basic',
          icon: 'heroicons_outline:presentation-chart-line',
          link: '/icp'
        },
        {
          id: 'icp-landing-page',
          title: 'Landing Pages',
          type: 'basic',
          icon: 'heroicons_outline:presentation-chart-line',
          link: '/icp-landing-page'
        },
        {
          type: 'group',
          title: 'Push communication'
        },
        {
          id: 'notifications',
          title: 'Notifications',
          type: 'basic',
          icon: 'heroicons_outline:bell',
          link: '/notifications',
        },
        {
          id: 'notification-templates',
          title: 'Notification Templates',
          type: 'basic',
          icon: 'heroicons_outline:bell',
          link: '/notification-templates',
        },
        {
          id: 'emails',
          title: 'Emails',
          type: 'basic',
          icon: 'heroicons_outline:at-symbol',
          link: '/email'
        },
        {
          id: 'email-keys',
          title: 'Email Keys',
          type: 'basic',
          icon: 'heroicons_outline:key',
          link: '/email-key'
        },
        {
          type: 'group',
          title: 'Marketing content'
        },
        {
          id: 'pages',
          title: 'Pages',
          type: 'basic',
          icon: 'heroicons_outline:document-text',
          link: '/pages',
        },
        {
          id: 'posts',
          title: 'Posts',
          type: 'basic',
          icon: 'heroicons_outline:rss',
          link: '/posts',
        },
        {
          id: 'slide_images',
          title: 'Slider Images',
          type: 'basic',
          icon: 'heroicons_outline:photo',
          link: '/slider-images',
        },
        {
          id: 'slides',
          title: 'Slides Intro',
          type: 'basic',
          icon: 'heroicons_outline:photo',
          link: '/slides-intro',
        },
        {
          type: 'group',
          title: 'HubSpot'
        },
        {
          id: 'hubspot_custom',
          title: 'HubSpot Custom Values',
          type: 'basic',
          icon: 'heroicons_outline:hubspot-icon',
          link: '/hubspot-custom/associations',
        },
        {
          type: 'divider'
        },
        {
          id: 'language',
          title: 'Language',
          type: 'basic',
          icon: 'heroicons_outline:language',
          link: '/language'
        }
      ]
    },
    {
      id: 'rules',
      title: 'Rules',
      type: 'aside',
      icon: 'heroicons_outline:building-library',
      children: [
        {
          type: 'group',
          title: 'Booking Rules',
        },
        {
          id: 'booking_rules',
          title: 'Booking Rules',
          type: 'basic',
          icon: 'heroicons_outline:check-circle',
          link: '/booking-rules'
        }
      ]
    },
    {
      id: 'config',
      title: 'App Config',
      type: 'basic',
      icon: 'heroicons_outline:cog',
      link: '/app-config',
    }
  ];

export const navigationData: Navigation = {
    compact: defaultNavItems,
    default: defaultNavItems,
    futuristic: defaultNavItems,
    horizontal: defaultNavItems
};