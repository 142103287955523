import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, catchError, from, map, of, switchMap, take, tap } from 'rxjs';
import { ParseUser } from 'app/core/user/parse-user.model';
import { navigationData } from 'app/mock-api/common/navigation/data';
import { AuthService } from '../auth.service';
import { UserService } from 'app/core/user/user.service';
import { FuseLoadingService } from '@fuse/services/loading';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  // Track if we're in the middle of checking auth to prevent redirects happening mid-check
  private _isChecking = false;
  
  // Track URLs that have been checked to prevent redirect loops
  private _checkedUrls = new Set<string>();

  constructor(
    private router: Router,
    private _authService: AuthService,
    private _userService: UserService,
    private _fuseLoadingService: FuseLoadingService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
    return this.check(redirectUrl);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
    return this.check(redirectUrl);
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.check('/');
  }

  private check(redirectURL: string): Observable<boolean> {
    // Check if we've already processed this URL to prevent loops
    if (this._checkedUrls.has(redirectURL)) {
      return of(true);
    }
    
    // Prevent loading bar from showing during auth checks
    const autoMode = this._fuseLoadingService['_auto$'].value;
    if (autoMode) {
      this._fuseLoadingService.setAutoMode(false);
    }
    
    // Guard against reentrant checks
    if (this._isChecking) {
      return of(true);
    }
    
    this._isChecking = true;
    
    // Make sure the auth service is initialized first
    if (!this._authService.initialized) {
      return this._authService.initialized$.pipe(
        take(1),
        switchMap(() => this.performAuthCheck(redirectURL)),
        tap(() => {
          // Restore loading bar behavior
          if (autoMode) {
            this._fuseLoadingService.setAutoMode(true);
          }
          this._isChecking = false;
          this._checkedUrls.add(redirectURL);
        })
      );
    }
    
    return this.performAuthCheck(redirectURL).pipe(
      tap(() => {
        // Restore loading bar behavior
        if (autoMode) {
          this._fuseLoadingService.setAutoMode(true);
        }
        this._isChecking = false;
        this._checkedUrls.add(redirectURL);
      })
    );
  }
  
  private performAuthCheck(redirectURL: string): Observable<boolean> {
    // First check if we have an auth token
    if (this._authService.accessToken) {
      // Verify the auth token and fetch user data
      return this._authService.check().pipe(
        switchMap((authenticated) => {
          if (!authenticated) {
            // If token is invalid or expired, redirect to sign-in
            this.router.navigate(['sign-in'], {
              queryParams: { redirectURL }
            });
            return of(false);
          }

          // Get current user from UserService
          return this._userService.get().pipe(
            map((user: ParseUser) => {
              return this.checkUserPermissions(user, redirectURL);
            }),
            catchError(() => {
              // Handle error in user fetch
              this.router.navigate(['sign-in'], {
                queryParams: { redirectURL }
              });
              return of(false);
            })
          );
        })
      );
    } else {
      // If no token, check for Parse user session
      const user = ParseUser.current() as ParseUser;

      if (!user) {
        // No user session, redirect to sign-in
        this.router.navigate(['sign-in'], {
          queryParams: { redirectURL }
        });
        return of(false);
      } else {
        return of(this.checkUserPermissions(user, redirectURL));
      }
    }
  }

  /**
   * Check user permissions for the requested URL
   */
  private checkUserPermissions(user: ParseUser, redirectURL: string): boolean {
    if (!user) {
      return false;
    }

    if (user.isAdmin && !['/dashboard', '/settings'].includes(redirectURL)) {
      const moduleName = redirectURL.split('/')[1];
      const navItem = navigationData.default.find(item => item.link === '/' + moduleName);

      // If the user's type === super_admin AND they have no permissions, they have access to everything
      const hasPermission = user.isSuperAdmin || (user.hasPermissions ? user.permissions?.includes(navItem?.id) : true);

      if (!hasPermission) {
        this.router.navigate(['dashboard']);
        return false;
      }
    } else if (!user.isAdmin) {
      this.router.navigate(['sign-in']);
      return false;
    }

    // Everything is fine, the user can access the requested route
    return true;
  }
}
