import { Injectable } from '@angular/core';
import { FuseLoadingService } from '@fuse/services/loading';
import { FuseConfigStorageService } from '@fuse/services/config/config-storage.service';
import { AuthService } from '../auth/auth.service';
import { BehaviorSubject, Observable, from, lastValueFrom } from 'rxjs';

/**
 * This service is responsible for managing the application initialization sequence
 * It prevents the ExpressionChangedAfterItHasBeenCheckedError by ensuring
 * loading bars and authentication state are properly initialized before
 * Angular's change detection starts running.
 */
@Injectable({ providedIn: 'root' })
export class AppInitializerService {
    private _initialized = false;
    private _initializing = false;
    private _initializedSubject = new BehaviorSubject<boolean>(false);
    
    // Store original service methods for restoration
    private _originalShowMethod: typeof FuseLoadingService.prototype.show;
    private _originalHideMethod: typeof FuseLoadingService.prototype.hide;
    private _originalSetAutoModeMethod: typeof FuseLoadingService.prototype.setAutoMode;
    private _originalSetModeMethod: typeof FuseLoadingService.prototype.setMode;
    private _originalSetProgressMethod: typeof FuseLoadingService.prototype.setProgress;

    /**
     * Observable to track initialization state
     */
    get initialized$(): Observable<boolean> {
        return this._initializedSubject.asObservable();
    }

    /**
     * Get initialized state
     */
    get initialized(): boolean {
        return this._initialized;
    }

    /**
     * Constructor
     */
    constructor(
        private _fuseLoadingService: FuseLoadingService,
        private _authService: AuthService,
        private _fuseConfigStorageService: FuseConfigStorageService
    ) {
        // Store original methods immediately
        this._originalShowMethod = this._fuseLoadingService.show;
        this._originalHideMethod = this._fuseLoadingService.hide;
        this._originalSetAutoModeMethod = this._fuseLoadingService.setAutoMode;
        this._originalSetModeMethod = this._fuseLoadingService.setMode;
        this._originalSetProgressMethod = this._fuseLoadingService.setProgress;
    }

    /**
     * Initialize the application
     * This method ensures all startup operations are complete before Angular
     * change detection runs for the first time in components
     */
    async initialize(): Promise<boolean> {
        // Exit if we've already initialized or are initializing
        if (this._initialized || this._initializing) {
            return this._initialized;
        }

        this._initializing = true;
        
        try {
            // Patch FuseLoadingService methods to prevent any loading state changes during initialization
            this.patchLoadingService();
            
            // Initialize the config storage service to load saved settings
            this._fuseConfigStorageService.init();
            
            // Run the auth service initialization
            // This will check authentication state without triggering HTTP requests
            await this._authService.initialize();
            
            // Restore FuseLoadingService methods after a delay
            // This ensures components are fully initialized before loading states can change
            setTimeout(() => {
                this.restoreLoadingService();
                
                // Mark initialization as complete
                this._initialized = true;
                this._initializing = false;
                this._initializedSubject.next(true);
                
            }, 500);
            
            return true;
        } catch (error) {
            console.error('AppInitializerService: Error during initialization:', error);
            
            // Restore service methods even if there's an error
            setTimeout(() => {
                this.restoreLoadingService();
                
                this._initializing = false;
                this._initializedSubject.next(true);
            }, 500);
            
            return false;
        }
    }
    
    /**
     * Patch the FuseLoadingService to prevent any loading state changes during initialization
     */
    private patchLoadingService(): void {
        
        // Replace methods with no-op versions
        this._fuseLoadingService.show = () => {
        };
        
        this._fuseLoadingService.hide = () => {
        };
        
        this._fuseLoadingService.setAutoMode = (value: boolean) => {
        };
        
        this._fuseLoadingService.setMode = (value: any) => {
        };
        
        this._fuseLoadingService.setProgress = (value: number) => {
        };
        
        // Force initial state
        (this._fuseLoadingService as any)._show$.next(false);
        (this._fuseLoadingService as any)._auto$.next(false);
    }
    
    /**
     * Restore original FuseLoadingService methods
     */
    private restoreLoadingService(): void {
        
        // Restore original methods
        this._fuseLoadingService.show = this._originalShowMethod;
        this._fuseLoadingService.hide = this._originalHideMethod;
        this._fuseLoadingService.setAutoMode = this._originalSetAutoModeMethod;
        this._fuseLoadingService.setMode = this._originalSetModeMethod;
        this._fuseLoadingService.setProgress = this._originalSetProgressMethod;
        
        // Set initial state after restoration
        this._fuseLoadingService.hide();
        this._fuseLoadingService.setAutoMode(true);
    }
}

/**
 * Factory function for APP_INITIALIZER provider
 */
export function initializeApp(appInitializer: AppInitializerService): () => Promise<boolean> {
    return () => appInitializer.initialize();
}
