import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ParseUser } from './core/user/parse-user.model';
import { ParseUserService } from './core/user/parse-user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _router: Router,
    private _userService: ParseUserService,
  ) { }

  ngOnInit(): void {
    this.loadUser();

    this._userService.userSessionExpired$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.onLogOut();
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
  }

  async loadUser(): Promise<void>{

    const user = ParseUser.current();

    if (user) {
      try {
        await user.fetch();
      } catch (error) {
        if (error.code === 209) {
          this.onLogOut();
        }
      }
    }

  }

  async onLogOut(): Promise<void> {

    try {
      await ParseUser.logOut();
    } catch (err) {
      if (err.code === 209) {
        this._router.navigate(['/sign-out']);
      }
    }

  }
}
