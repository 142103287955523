import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private _toggleDrawer$ = new BehaviorSubject<boolean>(false);

  /**
   * Constructor
   */
  constructor() { }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for toggle drawer
   */
  get toggleDrawer$(): Observable<boolean> {
    return this._toggleDrawer$.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle the settings drawer
   */
  toggleDrawer(): void {
    this._toggleDrawer$.next(true);
  }
}
