import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { EmailKeyModule } from 'app/modules/admin/email-key/email-key.module';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const routes: Route[] = [

  // Redirect empty path to '/dashboard'
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },

  // Redirect signed in user to the '/dashboard'
  //
  // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },

  // Auth routes for guests
  {
    path: '',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty'
    },
    children: [
      {
        path: 'forgot-password',
        loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)
      },
      {
        path: 'sign-in',
        loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)
      },
    ]
  },

  // Auth routes for authenticated users
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty'
    },
    children: [
      {
        path: 'sign-out',
        loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)
      },
    ]
  },

  // Admin routes
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,

    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('app/modules/admin/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'users',
        data: {
          type: 'super_admin'
        },
        loadChildren: () => import('app/modules/admin/user/user.module').then(m => m.UserModule)
      },
      {
        path: 'customers',
        data: {
          type: 'customer'
        },
        loadChildren: () => import('app/modules/admin/user/user.module').then(m => m.UserModule)
      },
      {
        path: 'vendors',
        data: {
          type: 'vendor'
        },
        loadChildren: () => import('app/modules/admin/vendor/vendor.module').then(m => m.VendorModule)
      },
      {
        path: 'icp',
        loadChildren: () => import('app/modules/admin/icp/icp.module').then(m => m.IcpModule)
      },
      {
        path: 'icp-landing-page',
        loadChildren: () => import('app/modules/admin/icp-landing-page/icp-landing-page.module').then(m => m.IcpLandingPageModule)
      },
      {
        path: 'customer-credits',
        loadChildren: () => import('app/modules/admin/customer-credits/customer-credits.module').then(m => m.CustomerCreditsModule)
      },
      {
        path: 'categories',
        loadChildren: () => import('app/modules/admin/category/category.module').then(m => m.CategoryModule)
      },
      {
        path: 'conversations',
        loadChildren: () => import('app/modules/admin/conversations/conversations.module').then(m => m.ConversationsModule)
      },
      {
        path: 'calls',
        loadChildren: () => import('app/modules/admin/calls/calls.module').then(m => m.CallsModule)
      },
      {
        path: 'bookings',
        loadChildren: () => import('app/modules/admin/bookings/bookings.module').then(m => m.BookingsModule)
      },
      {
        path: 'booking-fees',
        loadChildren: () => import('app/modules/admin/booking-fees/booking-fees.module').then(m => m.BookingFeesModule)
      },
      {
        path: 'email',
        loadChildren: () => import('app/modules/admin/email/email.module').then(m => m.EmailModule)
      },
      {
        path: 'email-key',
        loadChildren: () => import('app/modules/admin/email-key/email-key.module').then(m => m.EmailKeyModule)
      },
      {
        path: 'bulk-companies',
        loadChildren: () => import('app/modules/admin/enterprise-companies/enterprise-companies.module').then(m => m.EnterpriseCompaniesModule)
      },
      {
        path: 'bulk-deals',
        loadChildren: () => import('app/modules/admin/enterprise-deals/enterprise-deals.module').then(m => m.EnterpriseDealsModule)
      },
      {
        path: 'bulk-bookings',
        loadChildren: () => import('app/modules/admin/enterprise-bookings/enterprise-bookings.module').then(m => m.EnterpriseBookingsModule)
      },
      {
        path: 'equipment-categories',
        loadChildren: () => import('app/modules/admin/equipment-category/equipment-category.module').then(m => m.EquipmentCategoryModule)
      },
      {
        path: 'features',
        loadChildren: () => import('app/modules/admin/feature/feature.module').then(m => m.FeatureModule)
      },
      {
        path: 'booking-add-on-categories',
        loadChildren: () => import('app/modules/admin/booking-add-on-categories/booking-add-on-categories.module').then(m => m.BookingAddOnCategoriesModule)
      },
      {
        path: 'booking-add-on-templates',
        loadChildren: () => import('app/modules/admin/booking-add-on-templates/booking-add-on-templates.module').then(m => m.BookingAddOnTemplatesModule)
      },
      {
        path: 'booking-add-ons',
        loadChildren: () => import('app/modules/admin/booking-add-ons/booking-add-ons.module').then(m => m.BookingAddOnsModule)
      },
      {
        path: 'booking-failed-payments',
        loadChildren: () => import('app/modules/admin/booking-failed-payment/booking-failed-payment.module').then(m => m.BookingFailedPaymentModule)
      },
      {
        path: 'booking-rules',
        loadChildren: () => import('app/modules/admin/booking-rules/booking-rules.module').then(m => m.BookingRulesModule)
      },
      {
        path: 'flagged',
        loadChildren: () => import('app/modules/admin/flagged/flagged.module').then(m => m.FlaggedModule)
      },
      {
        path: 'dietary-restriction',
        loadChildren: () => import('app/modules/admin/dietary-restriction/dietary-restriction.module').then(m => m.DietaryRestrictionModule)
      },
      {
        path: 'language',
        loadChildren: () => import('app/modules/admin/language/language.module').then(m => m.LanguageModule)
      },
      {
        path: 'posts',
        loadChildren: () => import('app/modules/admin/post/post.module').then(m => m.PostModule)
      },
      {
        path: 'pages',
        loadChildren: () => import('app/modules/admin/page/page.module').then(m => m.PageModule)
      },
      {
        path: 'slider-images',
        loadChildren: () => import('app/modules/admin/slider-image/slider-image.module').then(m => m.SliderImageModule)
      },
      {
        path: 'slides-intro',
        loadChildren: () => import('app/modules/admin/slide-intro/slide-intro.module').then(m => m.SlideIntroModule)
      },
      {
        path: 'packages',
        loadChildren: () => import('app/modules/admin/package/package.module').then(m => m.PackageModule)
      },
      {
        path: 'user-insurance',
        loadChildren: () => import('app/modules/admin/user-insurance/user-insurance.module').then(m => m.UserInsuranceModule)
      },
      {
        path: 'goWalnut',
        loadChildren: () => import('app/modules/admin/go-walnut/go-walnut.module').then(m => m.GoWalnutModule)
      },
      {
        path: 'hubspot-custom',
        loadChildren: () => import('app/modules/admin/hubspot-custom/hubspot-custom.module').then(m => m.HubspotCustomModule)
      },
      {
        path: 'fuse',
        loadChildren: () => import('app/modules/admin/fuse/fuse.module').then(m => m.FuseModule)
      },
      {
        path: 'syzl-subscriptions',
        loadChildren: () => import('app/modules/admin/syzl-subscriptions/syzl-subscriptions.module').then(m => m.SyzlSubscriptionsModule)
      },
      {
        path: 'user-food-safety',
        loadChildren: () => import('app/modules/admin/user-food-safety/user-food-safety.module').then(m => m.UserFoodSafetyModule)
      },
      {
        path: 'user-packages',
        loadChildren: () => import('app/modules/admin/user-package/user-package.module').then(m => m.UserPackageModule)
      },
      {
        path: 'places',
        loadChildren: () => import('app/modules/admin/place/place.module').then(m => m.PlaceModule)
      },
      {
        path: 'place-city',
        loadChildren: () => import('app/modules/admin/place-city/place-city.module').then(m => m.PlaceCityModule)
      },
      {
        path: 'facilities',
        loadChildren: () => import('app/modules/admin/facility/facility.module').then(m => m.FacilityModule)
      },
      {
        path: 'reviews',
        loadChildren: () => import('app/modules/admin/review/review.module').then(m => m.ReviewModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('app/modules/admin/notification/notification.module').then(m => m.NotificationModule)
      },
      {
        path: 'settings',
        loadChildren: () => import('app/modules/admin/settings/settings.module').then(m => m.SettingsModule)
      },
      {
        path: 'smarter-u-account',
        loadChildren: () => import('app/modules/admin/smarter-u-account/smarter-u-account.module').then(m => m.SmarterUAccountModule)
      },
      {
        path: 'smarter-u-dummy',
        loadChildren: () => import('app/modules/admin/smarter-u-dummy/smarter-u-dummy.module').then(m => m.SmarterUDummyModule)
      },
      {
        path: 'twilio-numbers',
        loadChildren: () => import('app/modules/admin/twilio-numbers/twilio-numbers.module').then(m => m.TwilioNumbersModule)
      },
      {
        path: 'app-config',
        loadChildren: () => import('app/modules/admin/app-config/app-config.module').then(m => m.AppConfigModule)
      },
    ]
  }
];
