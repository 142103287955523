import { inject } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';

export abstract class TransAndSnackComponent {

  snackBar         = inject(MatSnackBar);
  translocoService = inject(TranslocoService);
  
  getTrans(key: string | string[], params: any = {}): string {
    return this.translocoService.translate(key, params);
  }

  getDefaultLang(): string {
    return this.translocoService.getDefaultLang();
  }

  showToast(message: string, action?: string, config: MatSnackBarConfig = { duration: 3000 }): void {
    this.snackBar.open(message, action, config);
  }
}
