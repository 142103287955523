import { Injectable } from '@angular/core';
import { FuseConfigService } from './config.service';

@Injectable({ providedIn: 'root' })
export class FuseConfigStorageService {
    private readonly STORAGE_KEY = 'fuse-config';

    /**
     * Constructor
     */
    constructor(private _fuseConfigService: FuseConfigService) {}

    /**
     * Initialize the config storage service
     */
    init(): void {
        // Load saved config from localStorage if it exists
        this.loadConfig();

        // Subscribe to config changes and save them
        this._fuseConfigService.config$.subscribe(config => {
            this.saveConfig(config);
        });
    }

    /**
     * Save the config to localStorage
     */
    saveConfig(config: any): void {
        if (!config) {
            return;
        }

        // Only save theme, scheme, and layout settings
        const configToSave = {
            theme: config.theme,
            scheme: config.scheme,
            layout: config.layout
        };

        // Save to localStorage
        localStorage.setItem(this.STORAGE_KEY, JSON.stringify(configToSave));
    }

    /**
     * Load the config from localStorage
     */
    loadConfig(): void {
        // Get the config from localStorage
        const storedConfig = localStorage.getItem(this.STORAGE_KEY);

        // If the config exists, load it
        if (storedConfig) {
            try {
                // Parse the config
                const config = JSON.parse(storedConfig);

                // Update the config
                this._fuseConfigService.config = config;
            } catch (error) {
                console.error('Error loading saved config:', error);
                // If there's an error, remove the invalid config
                localStorage.removeItem(this.STORAGE_KEY);
            }
        }
    }

    /**
     * Reset the config to default
     */
    resetConfig(): void {
        // Remove the config from localStorage
        localStorage.removeItem(this.STORAGE_KEY);

        // Reset the config
        this._fuseConfigService.reset();
    }
}
