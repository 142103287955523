import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import Parse from 'parse';
import { ParseUserService } from '../user/parse-user.service';

@Injectable()
export class ParseErrorInterceptor implements HttpInterceptor {
    
    constructor(private _parseUserService: ParseUserService) {}
    
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError(error => {
                // Check if this is a Parse error with an invalid session token
                if (error && error.code === Parse.Error.INVALID_SESSION_TOKEN) {
                    console.warn('Invalid session token detected in HTTP interceptor, triggering session expiration');
                    this._parseUserService.onUserSessionExpired();
                }
                
                return throwError(() => error);
            })
        );
    }
}
